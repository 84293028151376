@import url('https://fonts.googleapis.com/css2?family=Overpass&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import '@fontsource/poppins';
@import '@fontsource/raleway';
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
  justify-content: center;

}

.timeline {

  display: flex;
  margin: 0 auto;
  color: whitesmoke;
  max-width: 80%;

}


.timeline li,
ul {

  color: whitesmoke;
  text-align: flex-end;
  font-size: 17px;
  font-family: 'Nunito', sans-serif;
  border: 0px;

}


.likes-counter-container {

  display: flex;
  width: 400px;
  color: #FF4C98;
  font-size: 15px;
  font-family: 'Nunito', sans-serif;
  justify-content: center;
  align-items: center;
  height: fit-content;
}


.landing-greeting-emoji-container {

  display: flex;
  align-items: flex-end;
}

.emoji {
  unicode-bidi: isolate;
  display: inline-block;
  height: fit-content;
  width: fit-content;
  font-size: 40px;
  animation: emojifloat 2s ease-in-out infinite;
  padding: 5px;

}

@keyframes emojifloat {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0px);
  }
}

.react-logo-spin {
  animation: App-logo-spin infinite 10s linear;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.noselect {

  user-select: none;
  text-decoration: none;
}

.nav-item img:hover {

  border: none;
}

ul {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}

li {
  position: relative;
  padding: 10px;
  display: inline-block;
  margin: 0 auto;

}


/* Hide scrollbar for Chrome, Safari and Opera */
/* html::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge, and Firefox */
/* html {
  -ms-overflow-style: none;  /* IE and Edge */
/* scrollbar-width: none;  /* Firefox 
}  */


::-webkit-scrollbar {

  width: 10px;
  background-color: #201e1e;
}

::webkit-scrollbar-track {

  background-color: #201e1e;

}

::-webkit-scrollbar-thumb {

  height: 2px;
  width: 8px;
  border-radius: 10px;
  border: 2px solid #201e1e;
  background: #555;

}

::-webkit-scrollbar-thumb:hover {
  background-color: #FF4C98;
}

/* For IE and Edge */
* {
  -ms-overflow-style: auto;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {

  text-decoration: none;

}

a:visited,
a:focus,
a:active {
  color: #FF4C98;

}

li::marker {

  color: #FF4C98;
  font-size: 1.5rem;
  content: none;
}


a:hover {

  text-decoration: none;

}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 110px;
  background-color: rgba(32, 31, 31, 0.9);
  color: #FF4C98;
  text-align: center;
  font-size: 15px;
  padding: 5px 0;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  left: 1260px;
  top: 65px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;

}

.heading h1 {

  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: #FF4C98;
  margin: 0 auto;
  margin: 10px;
  padding: 10px;
  font-size: 50px;

}

.heading-desc {

  display: flex;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  background: linear-gradient(90deg, #FF4C98, #FF6EC7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 25px;

}

.link {
  display: inline-block;
  color: #FF4C98;
  /* Fallback color */
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  padding: 5px 5px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border: 2px solid transparent;
  border-radius: 25px;
  text-align: center;
}


html {
  scroll-behavior: smooth;
  overflow-x: hidden;

}

body {
  background-color: #121212;

}

.highlight {

  color: #FF4C98;
  font-family: 'Nunito', sans-serif;

}

.project-highlight {

  color: #FF4C98;
  padding-right: 10px;
  font-family: 'Nunito', sans-serif;

}

.javascript-highlight {

  color: #e0ec36;
  padding-right: 10px;
  font-family: 'Nunito', sans-serif;

}

.mongo-highlight {

  color: #3cf735;
  padding-right: 10px;
  font-family: 'Nunito', sans-serif;

}

.react-highlight {

  color: #3ad3e7;
  padding-right: 10px;
  font-family: 'Nunito', sans-serif;

}

.opengl-highlight {

  color: #6ea9e0;
  padding-right: 10px;
  font-family: 'Nunito', sans-serif;

}

.cplusplus-highlight {

  color: #6ea9e0;
  padding-right: 10px;
  font-family: 'Nunito', sans-serif;

}

.MuiSvgIcon-root MuiSvgIcon {
  justify-content: flex-start;
  align-items: flex-start;

}

.nav-heading a {
  display: flex;
  text-decoration: none;
  position: relative;
  margin: 0 auto;

}

.canvas-container {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

}

.canvas-container canvas {
  width: 100%;
  height: 100%;
}


.navigation {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 70px;
  text-align: center;


}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 25px;
  height: 25px;
  cursor: pointer;
}


.hamburger .bar {
  height: 3px;
  width: 100%;
  background-color: #FF4C98;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;

}

.nav-menu {
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 0px;
  transition: left 0.3s ease-in-out;
}

.nav-heading a {
  text-decoration: none;
  color: #FF4C98;
}

.download-button-container {

  display: flex;
  justify-content: flex-start;
  text-align: center;


}

.download-button,
.Linkedin-button,
.Github-button {
  display: flex;
  width: fit-content;
  height: 60px;
  margin-top: 20px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  border: 3px solid;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
  background: linear-gradient(90deg, #FF4C98, #FF6EC7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-color: #FF4C98;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

}

.download-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 400;
  color: #FF4C98;
  font-size: 21px;
  margin: 0 auto;
}

.icon {
  color: white;
}

.download-button:hover,
.Linkedin-button:hover,
.Github-button:hover,
.Project_card-button:hover {
  box-shadow:
    rgba(224, 70, 136, 0.4) 0px 5px,
    rgba(224, 70, 136, 0.3) 0px 10px,
    rgba(224, 70, 136, 0.2) 0px 15px,
    rgba(224, 70, 136, 0.1) 0px 20px,
    rgba(224, 70, 136, 0.05) 0px 25px;
}

.download-text:hover {
  text-decoration: none;
}

.landing-content-container {

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  padding: 100px;
  margin: 0 auto;
}

.landing-section-container {

  display: flex;
  width: 100%;
  height: 1000px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 0 auto;

}

.landing-greeting {

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Fira Code', monospace;
  font-size: 30px;
  width: fit-content;
  margin-top: 80px;
  margin-left: 10px;
  background: linear-gradient(90deg, #FF4C98, #FF6EC7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing-para {

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Fira Code', monospace;
  font-size: 23px;
  width: 60%;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-left: 10px;
  background: linear-gradient(90deg, #FF4C98, #FF6EC7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing-title {
  display: flex;
  font-family: 'Lexend Deca', sans-serif;
  margin-bottom: 10px;
  font-size: 80px;
  height: 100px;
  font-weight: 350;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(90deg, #FF4C98, #FF6EC7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.landing-desc {

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Fira Code', monospace;
  font-size: 28px;
  margin-left: 5px;
  width: fit-content;
  background: linear-gradient(90deg, #FF4C98, #FF6EC7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.landing-button-container {

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

}

.landing-button {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 80px;
  margin-top: 200px;
  margin-bottom: 150px;
  border: 1px solid transparent;
  scroll-behavior: smooth;
  background-clip: border-box;
  border-radius: 50px;
  background-color: transparent;


}

.landing-button:hover {

  transform: translateY(10px);
  transition: 0.3s ease;
  border-radius: 50px;
  animation: float 0.5s;

}

@keyframes float {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(10px);
  }
}

.projects {

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 0 auto;
  padding: 2%;

}

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 0px auto;
  border-radius: 15px;

}

.profile {

  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  border-radius: 75%;
  justify-content: center;
  margin: 0px auto 0px;
  border: 2px solid transparent;
  object-fit: cover;
  border: 3px solid;
  border-color: #FF4C98;
  box-shadow: #FF4C98 0px 1px 20px;

}

.profile:hover {

  box-shadow: #FF4C98 0px 1px 30px;
  transition: 0.10s ease;

}

.profile_Desc {

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 1.35;
  color: white;
  font-family: 'Nunito', sans-serif;
  max-width: 60%;
  opacity: 1.0;
}

.heading {

  display: flex;
  justify-content: center;
  align-items: center;
  color: #FF4C98;
  margin: 0 auto;
  font-weight: 200;
  text-align: center;
  position: relative;
  width: fit-content;
  height: fit-content;

}

.picture:hover {
  cursor: pointer;
}

.github-commit-chart {

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  justify-content: center;

}

.commit-chart iframe {
  height: 200px;
  margin: 10px;
  border: none;
}

.github-commit-chart .heading {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.Project_card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin: auto auto;
  padding: 20px;
  border-radius: 10px;
}

.Project_card-image img {
  flex: 1;
  margin-right: 50px;
  border-radius: 15px;
  border: #342834 2px solid;
}

.Project_card-image img:hover {
  border: #FF4C98 2px solid;
  transition: 0.6s;
}

.Project_card-image img {
  width: 500px;
  height: auto;
}

.Project_card-content {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
}

.Project_card-heading {
  font-size: 30px;
  background: linear-gradient(90deg, #FF4C98, #FF6EC7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  font-family: 'Lexend Deca', sans-serif;
  margin-bottom: 10px;
}

.Project_card-description {
  font-size: 18px;
  color: white;
  font-family: 'Nunito', sans-serif;
  margin-bottom: 20px;
}

.Project_card-button-container {
  display: flex;
  justify-content: flex-start;
}

.Project_card-button {
  width: fit-content;
  padding: 10px 20px;
  font-size: 16px;
  color: #FF4C98;
  background-color: transparent;
  border: 2px solid #FF4C98;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

}


.nav-heading {

  display: flex;
  text-align: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  position: relative;
  justify-content: center;
  align-items: center;
}


.nav-logo-heading {

  display: flex;
  width: 100%;
  background: linear-gradient(90deg, #FF4C98, #FF6EC7);
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 400;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 25px;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  margin-left: 30px;

}

#nav-profile img {
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 55px;
  margin: 10px;
  margin-right: 30px;
  object-fit: cover;
  overflow: hidden;
  border: 2px solid #FF4C98;

}

.nav-item {

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 300;
  margin: 0 auto;
  margin: 25px;

}

.nav-link a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #FF4C98; /* fallback color if gradients are not supported */
  background-image: -webkit-linear-gradient(90deg, #FF4C98, #FF6EC7); /* For Chrome 25 and Safari 6, iOS 6.1, Android 4.3 */
  background-image:    -moz-linear-gradient(90deg, #FF4C98, #FF6EC7); /* For Firefox (3.6 to 15) */
  background-image:      -o-linear-gradient(90deg, #FF4C98, #FF6EC7); /* For old Opera (11.1 to 12.0) */ 
  background-image:         linear-gradient(90deg, #FF4C98, #FF6EC7); /* Standard syntax; must be last */

  transform: scaleX(0);
  transition: transform 0.5s ease;
}

.nav-link a:hover::after{
  transform: scaleX(1);
}


#description {

  color: white;
  max-width: 340px;
  font-family: 'Nunito', sans-serif;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: 2px solid transparent;

}

#description:hover {
  color: #FF4C98;
  transition: ease 0.3s;

}

.main-content {

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  height: fit-content;
  width: 100%;
}

.content-desc-container {

  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80%;
}

.content-desc {

  font-size: 20px;
  flex: 1;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1.3;
  color: white;
  margin: 10px;
  padding: 30px;
  font-family: 'Nunito', sans-serif;
  text-align: flex-start;

}

.content-profile {

  flex: 1;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  line-height: 1.35;
  color: white;
  margin: 0 auto;
  font-family: 'Nunito', sans-serif;
  text-align: center;
}

.content-title {

  font-size: 20px;
  width: 75%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

}

.content-desc h1 {

  font-size: 25px;
  line-height: 1.5;
  color: #FF4C98;
  font-family: 'Nunito', sans-serif;
  margin: 0 20%;
  margin-bottom: 10px;
  width: 95%;
}


.stack-card-container {

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  height: fit-content;
  width: 80%;
}

.stack-card {

  height: 170px;
  width: fit-content;
  padding: 10px;
  border-radius: 15px;
  border: transparent 2px solid;
  justify-content: center;
  align-items: center;
  color: #FF4C98;
  margin: 10px;

}

.stack-card:hover {
  border: #FF4C98 2px solid;
  background-color: #1a1417;
  transition: 0.5s;
}

.stack-card:hover,
.stack-card:hover span {
  color: #FF4C98;
  transition: 0.9s;
}

.stack-card h1 {
  color: #FF4C98;
  font-size: 22px;
  font-family: 'Nunito', sans-serif;
  justify-content: center;
  align-items: center;

}

.stack-card img {

  height: 50px;
  width: auto;
  margin: 10px;

}

#stack-heading {

  display: flex;
  font-family: 'Nunito', sans-serif;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 340px;
  margin: 5px;
}

#BookIcon {
  padding: 10px;
}

.socials {
  display: flex;
  width: fit-content;
  padding: 5px;
  border-radius: 15px;
  border: 2px solid transparent;
  align-items: center;
  justify-content: center;
}

.socials1,
.socials2 {

  margin: 10px;
}

.Icons:hover {

  border: 1px solid;
  border-color: #FF4C98;
  transition: 0.10s;
  box-shadow: #FF4C98 0px 1px 10px;
  cursor: pointer;

}

.contactForm {

  display: flex;
  flex: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
  font-family: 'Nunito', sans-serif;
  color: #FF4C98;
  width: 100%;
}

.contact-desc-container {
  display: flex;
  font-family: 'Nunito', sans-serif;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  width: 75%;

}

.contact-desc {
  font-size: 18.5px;
  margin: 0 auto;
  color: white;
  text-align: center;
  margin: 10px;
  border: 2px solid rgb(46, 44, 44);
  padding: 10px;
  border-radius: 15px;
}

.contactForm input {

  margin: 10px;
  padding: 10px;
  border: 1px solid;
  border-color: #FF4C98;
  border-radius: 5px;
  background-color: #201e1e;
  color: #FF4C98;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;

}

#img src {

  border-radius: 50%;
  margin: 10px;

}

.contactForm input {

  height: 30px;
  width: 400px;
  margin: 10px;
  padding: 10px;
  border: 1px solid;
  border-color: #FF4C98;
  background-color: #201e1e;
  border-radius: 5px;
  color: #FF4C98;

}

.contactForm textarea {

  height: 80px;
  width: 400px;
  margin: 10px;
  padding: 10px;
  border: 1px solid;
  border-color: #FF4C98;
  background-color: #201e1e;
  font-family: 'Overpass', sans-serif;
  border-radius: 5px;
  color: #FF4C98;

}

.contactForm h2 {

  font-family: 'Overpass', sans-serif;
  color: #FF4C98;
  font-size: 25px;

}


.backToTop {

  display: flex;
  margin: 0 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: fit-content;
  height: fit-content;
  color: white;

}

.backToTop:hover {

  transform: translateY(-10px);
  transition: 0.5s ease;
  animation: anti-float 0.5s;

}

@keyframes anti-float {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.footer {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.footer-strip {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.footer-strip:last-child {
  border-right: none;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 17px;
  margin: 0 auto;
}

.footer-title {
  font-family: 'Lexend Deca', sans-serif;
  background: linear-gradient(90deg, #FF4C98, #FF6EC7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 10px;
  margin: 10px;
  text-align: center;
}

.footer-link {
  font-family: 'Lexend Deca', sans-serif;
  background: linear-gradient(90deg, #FF4C98, #FF6EC7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  text-align: flex-start;

}

.footer-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding: 10px;
  margin: 10px;
}

.footer-connect {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FF4C98;
}

.error-container {

  width: 440px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.contact-button {

  order: 2;
  flex: 1;
  margin: 10px;
  padding: 10px;
  border: 2px solid;
  border-color: #FF4C98;
  background-color: #201e1e;
  border-radius: 5px;
  color: #FF4C98;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;

}

.contact-Box {

  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;
  padding: 10px;
  box-sizing: border-box;
  margin: 20px auto;

}


#error-check {

  order: 1;
  flex: 1;
  margin: 10px;
  padding: 10px;
  display: flex;
  margin: 10px auto;
  color: #fc0000;
  font-family: 'Nunito', sans-serif;

}

.back-to-top {

  background-color: #FF4C98;

}

@media (min-width: 1600px) {

  .nav-item {
    background: linear-gradient(90deg, #FF4C98, #FF6EC7);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: #FF4C98;
    /* Ensures Firefox compatibility */
    display: inline-block;
  }

  @supports (-webkit-background-clip: text) {
    .nav-item {
      -webkit-text-fill-color: #FF4C98;
      /* Only for WebKit browsers */
    }
  }

}

@media (max-width: 1600px) and (min-width: 1200px) {

  .projects {
    width: 85%;
  }

  .landing-section-container {

    height: 850px;
  }

  .landing-content-container {


    margin-top: 0px;
    padding: 30px;
  }

  .landing-button {

    margin-top: 100px;
  }

  .landing-title {
    font-size: 60px;
  }

  .landing-desc {

    font-size: 30px;
    margin-bottom: 5px;
  }

  .nav-item {

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin: 0 auto;
    margin-top: 10px;
    margin-left: 30px;
    background: linear-gradient(90deg, #FF4C98, #FF6EC7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    -webkit-mask-image: none;

    @supports (-webkit-background-clip: text) {
      .nav-item {
        -webkit-text-fill-color: #FF4C98;
        /* Only for WebKit browsers */
      }
    }

  }

  .Project_card img {
    max-width: 100%;
    height: auto;
  }

  .Project_card {
    margin: 0 auto;
    padding: 10px;
    margin: 10px;

  }

  .stack-card-container {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: fit-content;
    width: 83%;

  }

  .stack-card {

    height: 170px;
    overflow: none;
    width: fit-content;
    margin: 10px;
    padding: 5px;
  }

  .stack-card #description {

    padding: 10px;
    justify-content: center;

  }

}

@media(min-width: 800px) and (max-width: 1200px) {

  .commit-chart iframe {
    height: 140px;
    border: none;
  }

  .nav-item {
    background: linear-gradient(90deg, #FF4C98, #FF6EC7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    margin: 5px;

  }

  @supports (-webkit-background-clip: text) {
    .nav-item {
      -webkit-text-fill-color: #FF4C98;
      /* Only for WebKit browsers */
    }
  }
}

@media (max-width: 800px) {

  .commit-chart iframe {
    height: 110px;
    border: none;
  }

  .nav-item {
    margin: 5px;
  }

  @supports (-webkit-background-clip: text) {
    .nav-item {
      -webkit-text-fill-color: #FF4C98;
      /* Only for WebKit browsers */
    }
  }

  .landing-content-container {

    padding: 150px;
    margin: 10px;
  }

  .landing-title {
    margin-top: 0px;
    font-size: 60px;
    margin: 0 auto;

  }

  .landing-desc {
    font-size: 30px;
    margin: 10px;
    margin: 0 auto;
  }

  .download-button-container {
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px;
  }

  .download-text {
    font-size: 15px;
  }

  .nav-logo-heading {
    width: fit-content;
  }

  .content-desc-container {
    width: 100%;
    display: grid;
    margin: 0 auto;
  }

  .content-title {
    padding: 0%;
  }

  .content-desc {

    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .Project_card-description {
    font-size: 16px;
    width: 80%;
  }


  .Project_card img {
    width: 100%;
    height: auto;
    object-fit: cover;

  }

  .Project_card {

    display: flex;
    flex-direction: column;
    max-width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin: 0 auto;
  }

  .nav-logo-heading {

    margin-right: 35%;
    margin: 0 auto;
  }

  .stack-card-container {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: fit-content;
    width: 83%;

  }

  .stack-card {

    height: 170px;
    overflow: none;
    width: fit-content;
    margin: 10px;
  }

  .stack-card #description {

    padding: 10px;
    justify-content: center;

  }

  .contact-Box {
    margin: 20px auto;
  }

  .contactForm,
  .contactForm input,
  .contactForm textarea {
    display: flex;
    margin: 10px auto;
    justify-content: flex-start;
    align-items: flex-start;

  }

  .contactForm input,
  .contactForm textarea {

    width: 230px;
    margin: 10px auto;
  }

  .contactForm button {

    font-size: 15px;

  }

  .error-container {
    margin: 0 auto;
    width: 85%;

  }

  .footer {
    flex-direction: column;

  }

}

@media (max-width: 1200px) {

  .projects {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }


  .heading {

    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF4C98;
    margin: 0 auto;

  }

  .navigation {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    margin: 0 auto;
  }

  .nav-heading a {
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    position: relative;
    padding: 5px;

  }

  .profile {

    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;

  }

  .profile-container {

    flex-direction: column;
    margin: 0 auto;
    justify-content: center;


  }


  .Icons {

    flex-direction: row;
    margin: 0 10px;

  }

  .Project_card img {

    margin: 0 auto;
    object-fit: contain;
  }


  .stack-card-container {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: fit-content;
    font-family: 'Poppins', sans-serif;
    width: 83%;

  }

  .stack-card {

    height: 170px;
    overflow: none;
    width: fit-content;
    margin: 10px;
  }

  .stack-card #description {

    padding: 10px;
    font-size: 16px;
    justify-content: center;

  }

  .contactForm,
  .contactForm input,
  .contactForm textarea {
    display: flex;
    width: auto;
    justify-content: flex-start;
    align-items: flex-start;

  }

  .contactForm input,
  .contactForm textarea {

    width: 230px;
    margin: 10px auto;
  }

  .contactForm button {

    font-size: 15px;

  }

  .error-container {
    margin: 0 auto;
    width: 85%;

  }


  .contact-Box {

    margin: 10px auto;
    justify-content: center;
    align-items: center;
    max-width: 60%;
  }


}

@media (max-width: 100px) {

  .heading h1 {

    margin: 0 auto;
    margin: 10px;
    justify-content: center;
    align-items: center;
  }


  .projects {

    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin: 10px;
    margin: 0 auto;

  }

  .Project_card {

    width: fit-content;
    margin: 10 auto;
  }

  .stack-card-container {

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: fit-content;
    font-family: 'Nunito', sans-serif;
  }

  .stack-card {

    height: fit-content;
    width: fit-content;
  }

  .stack-card #description {

    padding: 10px;
    font-size: 16px;
    justify-content: center;

  }

  .footer {
    flex-direction: column;
  }

}

@media (max-width: 480px) {

  .github-commit-chart {
    visibility: hidden;
    border: none;
  }

  .projects,
  .profile-container,
  .profile,
  .heading,
  .socials,
  .stack,
  .contact-desc-container {
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
  }

  .navigation {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: flex-end;
    position: absolute;
    top: 25px;
    right: 25px;
    transition: all 0.5s ease-in-out;
  }

  .hamburger.active .bar:nth-child(1) {

    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(2) {

    opacity: 0;
  }

  .hamburger.active .bar:nth-child(3) {

    transform: translateY(-8px) rotate(-45deg);
  }

  .nav-menu {
    display: flex;
    visibility: hidden;
    flex-direction: column;
    width: 100%;
    transform: translateY(-20px);
    transition: all 0.3s ease-in-out;
    height: 0px;

  }

  .nav-menu.active {
    display: flex;
    visibility: visible;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    height: 120px;

  }

  .nav-item {
    width: 30%;
    text-align: center;
    margin: 10px 0;
    transition: all 0.3s ease-in-out;
    transform: translateX(-50%);
    font-size: 15px;
    
  }

  .nav-menu.active .nav-item {
    transform: translateX(0);
    opacity: 1;
  }

  .nav-menu.active {
    width: 100%;
    text-align: center;
    transition: all 0.3s ease-in-out;
    transform: translateX(0);

  }

  .nav-menu:not(.active) .nav-item {
    transform: translateX(-50%);
    opacity: 0;
    transition-delay: 0s;
  }
  
  .nav-menu:not(.active) {
    transition-delay: 0.5s;
  }
  
  .nav-menu:not(.active) .nav-item {
    transform: translateX(-50%);
    opacity: 0;
    transition-delay: 0s;
  }
  
  .nav-menu:not(.active) {
    transition-delay: 0.5s;
  }

  .nav-menu.active .nav-item:nth-child(1) {
    transition-delay: 0.4s;
  }

  .nav-menu.active .nav-item:nth-child(2) {
    transition-delay: 0.45s;
  }

  .nav-menu.active .nav-item:nth-child(3) {
    transition-delay: 0.5s;
  }


  .nav-logo-heading {

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;

  }

  .nav-heading :hover {
    border-bottom: 2px solid #FF4C98;

  }

  .nav-logo-heading span {
    visibility: hidden;
  }

  .nav-heading a {
    display: block;
    padding: 5px 0;
  }

  .landing-section-container {

    height: fit-content;
    padding: 0px;
  }


  .landing-content-container {

    margin: 10px;
  }

  .landing-title {
    padding-top: 0px;
    font-size: 60px;
    margin: 0 auto;
    margin-left: 20px;
    height: 70px;

  }

  .landing-desc {
    justify-content: flex-start;
    font-size: 30px;
    margin: 0px auto;
    margin-left: 5px;
  }

  .download-button-container {
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px;
  }

  .download-text {
    font-size: 15px;
  }

  .content-desc-container {

    max-width: 100%;

  }

  .content-desc {
    font-size: 14px;
  }

  .stack-desc-container {

    overflow-x: hidden;
  }

  .nav-item {

    color: #FF4C98;
    padding: 0px;
    margin: 5px;

  }

  @supports (-webkit-background-clip: text) {
    .nav-item {
      -webkit-text-fill-color: #FF4C98;
      /* Only for WebKit browsers */
    }
  }

  .heading h1 {

    font-style: normal;
    margin: 10 auto;
    margin: 10px;
    font-size: 30px;

  }

  .heading-desc {

    font-size: 16px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 80%;
    justify-content: center;
    align-items: center;

  }

  .profile-container {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    width: 85%;
    box-sizing: border-box;
  }

  .profile_Desc {

    font-size: 14px;

  }

  .content-profile {
    width: 85%;
    margin: 10px auto;
    justify-content: center;
    align-items: center;
  }

  .content-desc-container {
    width: 100%;
    display: grid;
    margin: 0 auto;
  }

  .content-title {
    padding: 0%;
  }

  .content-desc {

    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 15px;
  }

  .Project_card-image {

    display: flex;
    width: 100%;
    height: auto;
    object-fit: contain;
    justify-content: flex-start;
    align-items: flex-start;

  }

  .profile {

    margin: 0 auto 30px;

  }

  .Project_card {

    flex-direction: column;
    margin: 0 auto;
    gap: 10px;
    max-width: 90%;
    justify-content: flex-start;
    align-items: flex-start;

  }

  .Project_card-content {

    width: 350px;

  }

  .Project_card-description {


    font-size: 14px;
  }

  .Project_card #heading {

    font-size: 23px;
    font-weight: 300;
  }

  .projects {

    grid-template-columns: 1fr;
    width: 100%;
  }

  .nav-logo-heading {

    font-size: 17px;
    justify-content: center;
    margin: 10px auto;
  }

  .stack-card-container {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 70%;
    margin: 0 auto;
  }

  .stack-card {
    display: flex;
    height: fit-content;
    flex-direction: column;
    overflow: hidden;
    width: fit-content;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    
  }

  #stack-heading {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width:150px;

  }

  #stack-heading img {
    display: flex;
    justify-content: flex-start;
    align-items: center;

  }

  .stack-card h1 {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;

  }

  .stack-card #description {

    padding: 1px;
    font-size: 14px;
    justify-content: center;
    display: none;

  }

  #stack-heading img {
    width: 30px;
    height: auto;
  }

  .Project_card button {

    font-size: 14px;
  }

  .footer {

    flex-direction: column;

  }

  .contact-Box {

    justify-content: center;
    align-items: center;
    max-width: 80%;
    margin: 20px auto;
  }

  .profile {


    height: 150px;
    width: 150px;
    margin: 0 auto;

  }

  .content-title {

    font-size: 16px;
    width: 100%;

  }

  .landing-section-container {

    height: fit-content;
    margin: 0 auto;
    padding: 0px;

  }

  .landing-content-container {

    height: fit-content;
    margin: 0 auto;
    padding: 0px;
    margin-left: 20px;
    margin-top: 0px;
  }

  .landing-para {

    margin-bottom: 20px;
    width: 300px;
    font-size: 20px;
  }

  .landing-greeting {

    font-size: 20px;
    margin-top: 20px;
    margin-left: 10px;
  }

  .landing-title {

    font-size: 45px;
    height: fit-content;
    width: fit-content;
    margin-left: 10px;
    margin-top: 10px;
  }

  .download-button-container {

    padding-bottom: 100px;
  }

  .landing-desc {
    margin-top: 10px;
    font-size: 18px;
    width: 100%;
  }

  .landing-button {

    display: none;

  }

  .socials {

    margin: 0 auto;
  }

  .projects {
    grid-template-columns: 1fr;

  }

  .profile_Desc {

    font-size: 16px;
    max-width: 100%;

  }

  .profile {

    margin: 0 auto 50px;

  }

  .nav-heading a {

    justify-content: center;
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    color: #FF4C98;
    position: relative;

  }

  .Project_card-image img {

    width: 50%;
    height: auto;
    object-fit: contain;
    margin: 0 auto;

  }

  .heading {

    margin: 0 auto;
  }

  .contact-desc {
    font-size: 15px;
    width: fit-content;
  }

  .timeline-container {

    display: none;

  }

  .timeline {

    display: flex;
    margin: 0 auto;
    color: whitesmoke;
    max-width: 80%;

  }

}

@media (max-width: 1200px) {
  .projects {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 5%;
    padding-right: 5%;

  }

  .nav-logo-heading {

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin: 0 auto;

  }

  .heading {

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    position: relative;
  }

  .profile_Desc {

    font-size: 20px;
    max-width: 100%;

  }

  .profile {

    margin: 0 auto 30px;

  }

  .Project_card {
    margin: 0 auto;
    width: 100%;

  }
}